import React from "react";
import { useState } from "react";
import CreateCategory from "./CreateCategory";
import CreateDepot from "./CreateDepot";
import CreatePlant from "./CreatePlant";
import CreateRegion from "./CreateRegion";
import CreateSubCategory from "./SubCategory";
import eventBus from "../../utils/EventBus";
import SetupAtcModal from "./SetupAtcModal";

export default function CementModals() {
  const [showCreateRegionModal, setShowCreateRegionModal] = useState(false);
  const [showCreatePlantModal, setShowCreatePlantModal] = useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [showCreateSubCategoryModal, setShowCreateSubCategoryModal] = useState(
    false
  );
  const [showCreateDepotModal, setShowCreateDepotModal] = useState(false);
  const [showSetupAtcModal, setShowSetupAtcModal] = useState(false);

  eventBus.useCustomEventListener(
    "showCreateRegionModal",
    setShowCreateRegionModal
  );
  eventBus.useCustomEventListener(
    "showCreatePlantModal",
    setShowCreatePlantModal
  );
  eventBus.useCustomEventListener(
    "showCreateCategoryModal",
    setShowCreateCategoryModal
  );
  eventBus.useCustomEventListener(
    "showCreateSubCategoryModal",
    setShowCreateSubCategoryModal
  );
  eventBus.useCustomEventListener(
    "showCreateDepotModal",
    setShowCreateDepotModal
  );
  eventBus.useCustomEventListener("showSetupAtcModal", setShowSetupAtcModal);

  return (
    <div>
      {showCreateRegionModal && (
        <CreateRegion
          showCreateRegionModal={showCreateRegionModal}
          setShowCreateRegionModal={setShowCreateRegionModal}
        />
      )}
      {showCreatePlantModal && (
        <CreatePlant
          showCreatePlantModal={showCreatePlantModal}
          setShowCreatePlantModal={setShowCreatePlantModal}
        />
      )}
      {showCreateCategoryModal && (
        <CreateCategory
          showCreateCategoryModal={showCreateCategoryModal}
          setShowCreateCategoryModal={setShowCreateCategoryModal}
        />
      )}
      {showCreateSubCategoryModal && (
        <CreateSubCategory
          showCreateSubCategoryModal={showCreateSubCategoryModal}
          setShowCreateSubCategoryModal={setShowCreateSubCategoryModal}
        />
      )}
      {showCreateDepotModal && (
        <CreateDepot
          showCreateDepotModal={showCreateDepotModal}
          setShowCreateDepotModal={setShowCreateDepotModal}
        />
      )}

      {showSetupAtcModal && (
        <SetupAtcModal
          showSetupAtcModal={showSetupAtcModal}
          setShowSetupAtcModal={setShowSetupAtcModal}
        />
      )}
    </div>
  );
}
