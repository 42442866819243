import Modal from "react-bootstrap/Modal";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import CreatableSelect from "react-select/creatable";
import { useFormik } from "formik";
import * as yup from "yup";
import { Table } from "react-bootstrap";
import { useState } from "react";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import { DeleteIcon } from "../Icons";

const components = {
  DropdownIndicator: null,
};

const CreateViewStorageBinsModal = (props) => {
  const { backendUrl } = useAuth();

  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState([]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    // eslint-disable-next-line default-case
    switch (event.key) {
      case "Enter":
      case "Tab":
        setValue((prev) => [...prev, { label: inputValue, value: inputValue }]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const addMoreUsers = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/storageBin`,
        "POST",
        "",
        payload
      ),
    {
      onSuccess: ({ data }) => {
        toast.success(data);
        setValue([]);
        props.refetch();
        props.onHide();
      },
      onError: ({ error }) => {
        toast.error("Unable to save storage bins");
      },
    }
  );

  const mutateDeleteUsers = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/storageBin`,
        "DELETE",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Storage bins deteled Successfully");
        setValue([]);
        props.refetch();
        props.onHide();
      },
      onError: () => {
        toast.error("Unable to delete storage bins");
      },
    }
  );

  const saveStorageBins = async () => {
    if (value.length < 1) return toast.error("Input storage bins");
    if (
      await ConfirmDialog({
        title: "Add Storage Bin",
        description: `Are you sure you want to add storage bin to this plant`,
      })
    ) {
      const values = {
        storage_bins: value.map((d) => d.label),
        Plant_ID: props?.singlePlant?.plantId,
      };
      addMoreUsers.mutate(values);
    }
  };

  const deleteUser = async (Storage_ID, Plant_ID) => {
    if (
      await ConfirmDialog({
        title: "Delete Storage Bin",
        description: `Are you sure you want to delete this Storage bin in this plant`,
      })
    ) {
      mutateDeleteUsers.mutate({ Storage_ID, Plant_ID });
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>{props?.singlePlant?.plantName.toUpperCase()}</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                x
              </div>
            </div>
            <p className="text-muted">You can add more storage bins here</p>
          </div>
          {/*  */}
          <div className="container mt-3">
            <div className="text-center">
              {/*  */}
              <div className="mb-3">
                <label className="col-form-label fw-bold">Storage Bins</label>
                <div className="form-group">
                  <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    menuIsOpen={false}
                    onChange={(newValue) => setValue(newValue)}
                    onInputChange={(newValue) => setInputValue(newValue)}
                    onKeyDown={handleKeyDown}
                    placeholder="Type something and press enter..."
                    value={value}
                  />
                </div>
              </div>
              {/*  */}
              <div className="text-center">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={saveStorageBins}
                >
                  Add Storage Bins
                </button>
              </div>
              {/*  */}
            </div>
          </div>
          {/*  */}
          <div className="my-4">
            <Table
              // responsive
              borderless
              striped
              className="product-table container"
            >
              <thead>
                <tr className="text-center">
                  <th>Full Name</th>
                  <th />
                </tr>
              </thead>
              <tbody className="text-center">
                {props?.singlePlant?.storageBins?.map((el, i) => (
                  <tr key={i}>
                    <td>{el.Storage_Name}</td>
                    <td>
                      <span
                        onClick={() =>
                          deleteUser(el.Storage_ID, props?.singlePlant?.plantId)
                        }
                        className="p-cursor"
                      >
                        <DeleteIcon />
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
      </div>
      <ModalLoader show={addMoreUsers.isLoading} />
    </Modal>
  );
};

export default CreateViewStorageBinsModal;
