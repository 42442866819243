import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useState } from "react";
import { Modal, Table, Form, Button, Dropdown } from "react-bootstrap";
// import "./../assets/scss/customer-select-modal.scss";
import useDebounce, {
  useEffectOnce,
  useSingleAndDoubleClick,
} from "../../utils/hooks";
import { services } from "../../config";
import queryString from "query-string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import {
  paginationOptions,
  qtyFormat,
  qtyFormatToString,
  scrollToTop,
  tonsToPcs,
  toTonsOrPcs,
} from "../../utils/helpers";
import currency from "currency.js";
import Datetime from "react-datetime";
import { format, startOfMonth, parse } from "date-fns";
import RsDateRangePicker from "../utils/RsDateRangePicker";
import { useAuth } from "../../hooks/useAuth";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { DeleteIcon, DropdownEyeIcon, EditIcon } from "../Icons";
import { toast } from "react-toastify";
import LoginDialog from "../LoginDialog";
import ConfirmDialog from "../ConfirmDialogue";
import CurrencyInput from "react-currency-input-field";
import BatchQuantityDialog from "../BatchQuantityDialog";
import { isEmpty } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import ModalLoader from "../utils/ModalLoader";
import ConvertQuantity from "../utils/ConvertQuantity";
import { Link } from "react-router-dom";

export default function BatchesModal({
  selectedItem,
  setSelectedItem,
  setShowBatchesModal,
}) {
  const initialFilterParams = {
    Bar_Code: selectedItem?.Bar_Code,
    RegDate: "",
    InvoiceNo: "",
  };
  const queryClient = useQueryClient();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, isIronRod } = useAuth();
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 40,
    ...initialFilterParams,
  });
  const debouncedqueryParams = useDebounce(queryParams, 800);

  const getBatches = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/items/batches?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { batches: [] }, refetch } = useQuery(
    ["BATCHES", debouncedqueryParams],
    () => getBatches(debouncedqueryParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const handleSearchQueryChange = (e) => {
    setQueryParams({
      ...queryParams,
      page: 1,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const deleteBatch = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/delete-batch`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const deleteMutation = useMutation((payload) => deleteBatch(payload), {
    onSuccess: ({ message, data: { batch } }) => {
      toast.success(message);
      queryClient.setQueryData(["BATCHES", queryParams], (data) => {
        data.batches = data.batches.filter(
          (el) => el.Bar_Code !== batch.Bar_Code
        );
        return data;
      });
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const updateBatch = async (payload) => {
    let response = await fetch(`${backendUrl}/api/items/update-batch`, {
      method: "POST",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        ...payload,
      }),
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };
  const updateBatchMutation = useMutation((payload) => updateBatch(payload), {
    onSuccess: () => {
      toast.success(`Success`);
      refetch();
    },
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const handleDelete = async (batch) => {
    if (await LoginDialog()) {
      if (
        await ConfirmDialog({
          title: "Delete Batch",
          description:
            "Are you sure you want to delete this Batch from your inventory",
        })
      ) {
        deleteMutation.mutate(batch);
      }
    }
  };

  const handleEdit = async (batch) => {
    if (await LoginDialog()) {
      const tonsAndPcs = qtyFormat(
        batch.Quantity,
        data?.item?.Item_Desc,
        itemMeasurements
      );
      const Tons = tonsAndPcs.split("-")[0];
      const Pieces = tonsAndPcs.split("-")[1];
      const formatType = tonsAndPcs.split("-")[2];

      const values = await BatchQuantityDialog({
        batch,
        Tons,
        Pieces,
        formatType,
      });

      if (!isEmpty(values)) {
        let UnitCost = Number(
          currency(values.UnitCost, { symbol: "", separator: "" }).format()
        );

        let UnitPrice = Number(
          currency(values.UnitPrice, { symbol: "", separator: "" }).format()
        );

        let VAT = Number(
          currency(values.VAT, { symbol: "", separator: "" }).format()
        );

        let oldQuantity = Number(
          currency(batch.Quantity, { symbol: "", separator: "" }).format()
        );

        const tons = values?.Tons
          ? tonsToPcs(values?.Tons, data?.item?.Item_Desc, itemMeasurements)
          : 0;

        let Quantity = Number(
          currency(tons, { symbol: "", separator: "" })
            .add(values.Pieces)
            .format()
        );

        updateBatchMutation.mutate({
          batch,
          UnitCost,
          UnitPrice,
          Quantity,
          oldQuantity,
          VAT,
        });
      }
    }
  };

  return (
    <Modal
      show={true}
      onHide={() => {
        setSelectedItem(null);
        setShowBatchesModal(false);
      }}
      dialogClassName="requisition-details-modal approve-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1 className="h5">Batch Details</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4 d-flex flex-column pt-0">
        <div className="search-area d-flex justify-content-between align-items-center gap-3 w-100 px-3 mb-4 h6">
          <h2>
            {selectedItem?.Item_Name} / {selectedItem?.Bar_Code}
          </h2>

          <Form.Group>
            <Form.Check
              type="switch"
              label="Show All"
              name="showAll"
              className="ga-form-check fs-6"
              checked={queryParams.showAll}
              onChange={handleSearchQueryChange}
            />
          </Form.Group>
        </div>

        <div className="col-6 d-flex gap-3 align-items-center pe-3 mb-3">
          <div className="global-search-area w-75">
            <MagnifyIcon />
            <input
              className="form-control search-input"
              name="InvoiceNo"
              value={queryParams.InvoiceNo}
              onChange={(e) =>
                setQueryParams({
                  ...queryParams,
                  page: 1,
                  [e.target.name]: e.target.value,
                })
              }
              placeholder="Invoice number"
              autoFocus
              autoComplete="off"
              type={"text"}
            />
          </div>

          <Datetime
            dateFormat="MMM DD, YYYY"
            timeFormat={false}
            closeOnSelect={true}
            closeOnClickOutside={true}
            inputProps={{
              className: `date-input form-control`,
              placeholder: "Select Reg Date",
              readOnly: true,
            }}
            value={queryParams.RegDate}
            onChange={(date) => {
              setQueryParams({
                ...queryParams,
                RegDate: date,
              });
            }}
          />
        </div>

        <div className="content px-1 pb-4 pe-2 flex-grow-1">
          <Table borderless responsive striped className="product-table">
            <thead className="sticky border-bottom">
              <tr>
                <th />
                <th>Batch</th>
                <th>Invoice No</th>
                <th>Unit Cost</th>
                <th>Quantity</th>
                <th>Sub-Total</th>
                <th>Unit Price</th>
                {selectedItem?.Product_Model === "Tons" ? (
                  <th>Unit Price (Pcs)</th>
                ) : null}
                <th>Reg Date</th>

                <th>Man Date</th>

                <th>Exp Date</th>

                <th>Driver Name</th>
                <th>Phone Number</th>
                <th>Truck Number</th>
                <th>Delivery Date</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data?.batches &&
                data.batches.map((el, index) => (
                  <tr key={index} className={`p-cursor`}>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-white border-0"
                          bsPrefix="print more"
                        >
                          <DotsVerticalIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="dropdown-with-icons"
                        >
                          {el?.InvoiceNo && (
                            <Dropdown.Item as="div">
                              <Link
                                to={`/reports/purchase-history?NoDate=${true}&invoiceNumber=${
                                  el?.InvoiceNo
                                }`}
                              >
                                <DropdownEyeIcon /> Open in Purchase History
                              </Link>
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleEdit(el)}
                          >
                            <EditIcon /> Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            as="button"
                            onClick={() => handleDelete(el)}
                          >
                            <DeleteIcon /> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>
                      {el.Bar_Code ? el.Bar_Code.split("-").pop() : "..."}
                    </td>
                    <td>
                      {el?.InvoiceNo ? (
                        <Link
                          to={`/reports/purchase-history?NoDate=${true}&invoiceNumber=${
                            el?.InvoiceNo
                          }`}
                        >
                          {el?.InvoiceNo}
                        </Link>
                      ) : (
                        "..."
                      )}
                    </td>
                    <td>
                      {currency(el.UnitCost, {
                        symbol: "",
                      }).format()}
                    </td>

                    <td className="text-nowrap">
                      <ConvertQuantity
                        quantity={el.Quantity}
                        desc={data?.item?.Item_Desc}
                      />
                    </td>

                    <td>
                      {currency(el.subTotal, {
                        symbol: "",
                      }).format()}
                    </td>
                    <td>
                      {currency(el.UnitPrice, {
                        symbol: "",
                      }).format()}
                    </td>
                    {selectedItem?.Product_Model === "Tons" ? (
                      <td>
                        {currency(el.VAT, {
                          symbol: "",
                        }).format()}
                      </td>
                    ) : null}
                    <td>
                      {el.RegDate
                        ? format(new Date(el.RegDate), "dd MMM, yyyy")
                        : "..."}
                    </td>
                    <td>
                      {el.ManDate
                        ? format(new Date(el.ManDate), "dd MMM, yyyy")
                        : "..."}
                    </td>
                    <td>
                      {el.ExpireDate
                        ? format(new Date(el.ExpireDate), "dd MMM, yyyy")
                        : "..."}
                    </td>
                    <td>{el?.DriverName}</td>
                    <td>{el?.DriverPhoneNumber}</td>
                    <td>{el?.TruckNumber}</td>
                    <td>
                      {el?.DeliveryDate
                        ? format(new Date(el.DeliveryDate), "dd MMM, yyyy")
                        : "..."}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between px-3 align-items-center pagination">
          <p className="m-0">
            Showing {data?.startIndex + 1} to{" "}
            {data?.endIndex <= data?.count ? data?.endIndex : data?.count} of{" "}
            {data.count} entries
          </p>

          <p className="fw-6">
            <ConvertQuantity
              quantity={data?.totalQty}
              desc={data?.item?.Item_Desc}
            />
          </p>

          <p className="fw-6">
            {currency(data?.valueInStock, { symbol: "" }).format()}
          </p>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              scrollToTop();
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>
      </Modal.Body>

      <ModalLoader show={updateBatchMutation.isLoading} />
    </Modal>
  );
}
