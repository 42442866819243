import { Modal, Form, Button } from "react-bootstrap";
import "../../../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import CurrencyCustomInput from "../../utils/CurrencyCustomInput";
import NumberCustomInput from "../../utils/NumberCustomInput";
import DateTime from "react-datetime";
import { useAuth } from "../../../hooks/useAuth";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { queryActions } from "../../../utils/reactQueryActions";
import { useFormik } from "formik";
// import { useEffect, useMemo, useState } from "react";
import { useMemo, useState } from "react";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";
// import ItemSizeDialog from "../../ItemSizeDialog";
import currency from "currency.js";
import moment from "moment";
import PurchaseDialog from "../../PurchaseDialog";
import { toast } from "react-toastify";
import NewVendorModal from "../../NewVendorModal";
// import { useStoreActions, useStoreState } from "easy-peasy";
// import { Units, fetchActionsUtil } from "../../../utils/helpers";
import { fetchActionsUtil } from "../../../utils/helpers";
// import { appSettings } from "../../../config";

const usageOptions = [
  {
    label: "Sales",
    value: "Sales",
  },
  {
    label: "Office Use",
    value: "Office Use",
  },
  {
    label: "Promo",
    value: "Promo",
  },
];

const maintainceData = [
  {
    label: "",
    value: "",
  },
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const Validity = [
  {
    label: "",
    value: "",
  },
  {
    label: "1 Month",
    value: "1",
  },
  {
    label: "2 Months",
    value: "2",
  },
  {
    label: "3 Months",
    value: "3",
  },
  {
    label: "4 Months",
    value: "4",
  },
  {
    label: "5 Months",
    value: "5",
  },
  {
    label: "6 Months",
    value: "6",
  },
  {
    label: "7 Months",
    value: "7",
  },
  {
    label: "8 Months",
    value: "8",
  },
  {
    label: "9 Months",
    value: "9",
  },
  {
    label: "10 Months",
    value: "10",
  },
  {
    label: "11 Months",
    value: "11",
  },
  {
    label: "12 Months",
    value: "12",
  },
];

export default function AddNewAssets({
  showCreateNewInventoryModal,
  setShowCreateNewInventoryModal,
  setItemType,
  refetch,
}) {
  const { backendUrl, token } = useAuth();
  const queryClient = useQueryClient();
  const [showCreateNewVendorModal, setShowCreateNewVendorModal] = useState(
    false
  );

  const [accountName, setAccountName] = useState("");

  //   const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  //   const setItemMeasurements = useStoreActions(
  //     (actions) => actions.setItemMeasurements
  //   );

  const setUp = async () => {
    let response = await fetch(`${backendUrl}/api/items/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const warehouses = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/categories/${setItemType?.cater}`,
      "GET"
    );

    const storageLocation = await fetchActionsUtil(
      `${backendUrl}/api/StorageLocation/${setItemType?.whID}`,
      "GET"
    );

    const singleWarehouse = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/${setItemType?.namewh}`,
      "GET"
    );

    setAccountName(singleWarehouse?.warehouses[0]?.WH_Accounts?.Description);

    const { data } = await response.json();

    data.vendors = data?.vendors.map((el) => ({
      label: el.CompanyName,
      value: el.Vendor_ID,
    }));

    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.staffs = data?.staffs.map((el) => ({
      value: el.Name,
      label: el.Name,
    }));

    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    data.warehouses = warehouses?.warehouses
      ?.filter((e) => e.W_ID === setItemType?.whID)
      ?.map((el) => ({
        value: el.W_ID,
        label: el.W_name,
      }));

    data.storagelocation = storageLocation.storagelocation.map((el) => ({
      value: el.Storage_ID,
      label: el.storage_location,
    }));

    console.log(data);

    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
    },
  } = useQuery([queryActions.ITEMS_SETUP], () => setUp(), {
    enabled: true,
  });

  const formik = useFormik({
    initialValues: {
      Serial_Number: "",
      Vendor: "",
      Item_Type: "Inventory",
      Bar_Code: "",
      Item_Name: "",
      InvoiceNo: "",
      //   Size: appSettings.isBatchStandard ? "" : "8 mm",
      //   Unit: appSettings.isBatchStandard ? "Each" : "Tons",
      Size: "",
      Unit: "",
      Usage: "Sales",
      //   PurchasePrice: "",
      //   Freight: "",
      UnitCost: "",
      Quantity: "",
      ExpireDate: moment(),
      Product_Name: "",
      Cat_Name: "",
      Branch: "HQ",
      Reorder_Level: "5",
      Tax: "",
      Warehouse: "",
      StorageLocation: "",
      Date_Log: moment(),
      Item_Desc: "",
      ManDate: moment(),
      Calibration: "",
      ValidityofCal: "",
      LastCalibration: moment(),
      Maintain_By: "",
      Certified_By: "",
    },
    validationSchema: yup.object().shape({
      Serial_Number: yup.string().required("Item Code is required"),
      Bar_Code: yup.string().required("Item Code is required"),
      Item_Name: yup.string().required("Item Name is required"),
      //   InvoiceNo: yup.string().required("Invoice number is required"),
      Product_Name: yup.string().required("required"),
      Cat_Name: yup.string().required("required"),
      Vendor: yup.string().required("reqiured"),
      //   PurchasePrice: yup.string().required("reqiured"),
      //   UnitPrice: yup.string().required("reqiured"),
      Quantity: yup.string().required("reqiured"),
      Warehouse: yup.string().required("reqiured"),
      // StorageLocation: yup.string().required("reqiured"),
      // Maintain_By: yup.string().required("reqiured"),
      // Certified_By: yup.string().required("reqiured"),
    }),
    onSubmit: async (values) => {
      values.vendorName = data?.vendors.find(
        (el) => el.value === values.Vendor
      )?.label;
      //   values.InvoiceAmount = InvoiceAmount;

      const purchase = await PurchaseDialog({
        item: values,
      });

      values.totalLandingCost = totalLandingCost;
      values.accountName = accountName;
      values.NextCalDate = nextmaintance;

      console.log(values, purchase);

      createMutation.mutate({
        newItem: values,
        vendorName: values.vendorName,
        purchaseHistory: purchase,
        //   isBatchStandard: appSettings.isBatchStandard,
        isBatchStandard: "",
      });
    },
    onReset: () => {},
  });

  const createItem = async (payload) => {
    let response = await fetch(`${backendUrl}/api/warehouse/assets`, {
      method: "POST",
      credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const createMutation = useMutation((payload) => createItem(payload), {
    onSuccess: ({ message }) => {
      toast.success(message);
      formik.resetForm();
      if (refetch) refetch();
      setShowCreateNewInventoryModal(false);
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const nextmaintance = useMemo(() => {
    return moment(formik.values.LastCalibration).add(
      formik.values.ValidityofCal,
      "months"
    );
  }, [formik.values.LastCalibration, formik.values.ValidityofCal]);

  //   useEffect(() => {
  //     const UnitCost = currency(formik.values.PurchasePrice, {
  //       symbol: "",
  //       separator: "",
  //     })
  //       .add(formik.values.Freight)
  //       .format();
  //     formik.setFieldValue("UnitCost", UnitCost);
  //   }, [formik.values.PurchasePrice, formik.values.Freight]);

  //   const InvoiceAmount = useMemo(
  //     () =>
  //       currency(formik.values.PurchasePrice, {
  //         symbol: "",
  //         separator: "",
  //       })
  //         .multiply(formik.values.Quantity)
  //         .format(),
  //     [formik.values.PurchasePrice, formik.values.Quantity]
  //   );

  //   const addItemMeasurement = async () => {
  //     const newSize = await ItemSizeDialog();
  //     if (newSize) {
  //       queryClient.setQueryData([queryActions.ITEMS_SETUP], (data) => {
  //         data.itemMeasurement = [
  //           ...data.itemMeasurement,
  //           { value: newSize.Size, label: newSize.Size },
  //         ];
  //         return data;
  //       });
  //       formik.setFieldValue("Size", newSize.Size);

  //       // add to store
  //       // console.log(newSize);
  //       setItemMeasurements([...itemMeasurements, newSize]);
  //     }
  //   };

  const setSelectedVendor = (vendor) => {
    if (vendor) {
      queryClient.setQueryData([queryActions.ITEMS_SETUP], (data) => {
        data.vendors = [
          ...data.vendors,
          { label: vendor.CompanyName, value: vendor.Vendor_ID },
        ];
        return data;
      });
      formik.setFieldValue("Vendor", vendor.Vendor_ID);
    }
  };

  const totalLandingCost = useMemo(() => {
    return currency(formik.values.UnitCost, {
      symbol: "",
      separator: "",
    })
      .multiply(formik.values.Quantity)
      .format();
  }, [formik.values.UnitCost, formik.values.Quantity]);

  return (
    <Modal
      show={showCreateNewInventoryModal}
      onHide={() => setShowCreateNewInventoryModal(false)}
      dialogClassName="new-entity-modal"
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>Add New Product Information</h1>
          <p>Add a new item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-0">
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className="row new-item-form w-100 m-0"
          autoComplete="off"
        >
          <div className="col-md-6 px-4">
            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Serial Number</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Serial Number"
                  name="Serial_Number"
                  value={formik.values.Serial_Number}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Serial_Number &&
                    !!formik.errors.Serial_Number
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Bar_Code}
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Item Code</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Item Code"
                  name="Bar_Code"
                  value={formik.values.Bar_Code}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Bar_Code && !!formik.errors.Bar_Code
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Bar_Code}
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}

              {/*  */}
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Product</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.products}
                  value={data?.products?.find(
                    (el) => el.value === formik.values.Product_Name
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Product_Name", value)
                  }
                />
                {formik.touched.Product_Name && formik.errors.Product_Name ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Product_Name}
                  </span>
                ) : null}
              </Form.Group>
              {/*  */}
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Category</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.categories}
                  value={data?.categories?.find(
                    (el) => el.value === formik.values.Cat_Name
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Cat_Name", value)
                  }
                />
                {formik.touched.Cat_Name && formik.errors.Cat_Name ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Cat_Name}
                  </span>
                ) : null}
              </Form.Group>

              <div className="row text-nowrap align-items-center">
                {/* <Form.Group className="col-6 mb-3 pb-2">
                  <Form.Label className="mb-1">Unit</Form.Label>
                  <Select
                    classNamePrefix={`form-select`}
                    options={Units}
                    value={Units.find((el) => el.value === formik.values.Unit)}
                    onChange={({ value }) => {
                      formik.setFieldValue("Unit", value);
                      if (!["Tons", "Pieces"].includes(value)) {
                        formik.setFieldValue("Size", "");
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group> */}
                {/* 
                {["Tons", "Pieces"].includes(formik.values.Unit) && (
                  <>
                    <Form.Group className="col-6 mb-3 pb-2">
                      <Form.Label className="mb-1">Size</Form.Label>
                      <Select
                        classNamePrefix={`form-select`}
                        options={data?.itemMeasurement}
                        value={data?.itemMeasurement.find(
                          (el) => el.value === formik.values.Size
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("Size", value)
                        }
                      />
                    </Form.Group>

                    <Form.Group className="col-2 pb-2 px-0">
                      <Button size="sm" onClick={() => addItemMeasurement()}>
                        + Add
                      </Button>
                    </Form.Group>
                  </>
                )} */}
              </div>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Item Name</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Item Name"
                  name="Item_Name"
                  value={formik.values.Item_Name}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.Item_Name && !!formik.errors.Item_Name
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Item_Name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label>Quantity</Form.Label>
                <NumberCustomInput
                  placeholder="0"
                  name="Quantity"
                  value={formik.values.Quantity}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.Quantity && !!formik.errors.Quantity
                  }
                  onBlur={() => formik.setFieldTouched("Quantity", true)}
                />
                {formik.touched.Quantity && !!formik.errors.Quantity ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Quantity}
                  </span>
                ) : null}
              </Form.Group>
              {/*  */}
              <div className="row text-nowrap align-items-center">
                <Form.Group className="col-10 mb-3 pb-2">
                  <Form.Label className="mb-1">Vendor</Form.Label>
                  <Select
                    classNamePrefix={`form-select`}
                    options={data.vendors}
                    value={data?.vendors?.find(
                      (el) => el.value === formik.values.Vendor
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("Vendor", value)
                    }
                  />
                  {formik.touched.Vendor && formik.errors.Vendor ? (
                    <span className="text-danger mt-2">
                      {formik.errors.Vendor}
                    </span>
                  ) : null}
                </Form.Group>

                <Form.Group className="col-2 pb-2 px-0">
                  <Button
                    size="sm"
                    onClick={() => setShowCreateNewVendorModal(true)}
                  >
                    + Add
                  </Button>
                </Form.Group>
              </div>
              {/*  */}
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Invoice No</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Invoice No"
                  name="InvoiceNo"
                  value={formik.values.InvoiceNo}
                  onChange={formik.handleChange}
                  isInvalid={
                    formik.touched.InvoiceNo && !!formik.errors.InvoiceNo
                  }
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.InvoiceNo}
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Usage</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={usageOptions}
                  value={usageOptions.find(
                    (el) => el.value === formik.values.Usage
                  )}
                  onChange={({ value }) => formik.setFieldValue("Usage", value)}
                />
              </Form.Group>

              {/*  */}
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Cost Price</Form.Label>
                <CurrencyCustomInput
                  name="UnitPrice"
                  placeholder="0.00"
                  value={formik.values.UnitPrice}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitPrice && !!formik.errors.UnitPrice
                  }
                  onBlur={() => formik.setFieldTouched("UnitPrice", true)}
                />
                {formik.touched.UnitPrice && !!formik.errors.UnitPrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitPrice}
                  </span>
                ) : null}
              </Form.Group>

              {/*  */}
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Tax</Form.Label>
                <CurrencyCustomInput
                  name="Tax"
                  placeholder="0.00"
                  value={formik.values.Tax}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Tax && !!formik.errors.Tax}
                  onBlur={() => formik.setFieldTouched("Tax", true)}
                />
                {formik.touched.Tax && !!formik.errors.Tax ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Tax}
                  </span>
                ) : null}
              </Form.Group>

              {/*  */}
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Unit Cost</Form.Label>
                <CurrencyCustomInput
                  name="UnitCost"
                  placeholder="0.00"
                  value={formik.values.UnitCost}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.UnitCost && !!formik.errors.UnitCost
                  }
                  onBlur={() => formik.setFieldTouched("UnitCost", true)}
                />
                {formik.touched.UnitCost && !!formik.errors.UnitCost ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.UnitCost}
                  </span>
                ) : null}
              </Form.Group>
              {/*  */}
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Size</Form.Label>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter Size"
                  name="Size"
                  value={formik.values.Size}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.Size && !!formik.errors.Size}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.Size}
                </Form.Control.Feedback>
              </Form.Group>
              {/*  */}
            </div>
          </div>
          {/* side */}
          <div className="col-md-6 px-4">
            <div className="row">
              {/* <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Purchase Price</Form.Label>
                <CurrencyCustomInput
                  name="PurchasePrice"
                  placeholder="0.00"
                  value={formik.values.PurchasePrice}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={
                    formik.touched.PurchasePrice &&
                    !!formik.errors.PurchasePrice
                  }
                  onBlur={() => formik.setFieldTouched("PurchasePrice", true)}
                />
                {formik.touched.PurchasePrice &&
                !!formik.errors.PurchasePrice ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.PurchasePrice}
                  </span>
                ) : null}
              </Form.Group> */}

              {/* <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Freight</Form.Label>
                <CurrencyCustomInput
                  name="Freight"
                  placeholder="0.00"
                  value={formik.values.Freight}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                  isInvalid={formik.touched.Freight && !!formik.errors.Freight}
                  onBlur={() => formik.setFieldTouched("Freight", true)}
                />
                {formik.touched.Freight && !!formik.errors.Freight ? (
                  <span className="custom-invalid-feedback">
                    {formik.errors.Freight}
                  </span>
                ) : null}
              </Form.Group> */}

              {/*  */}

              {/* <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label title={totalLandingCost} className="mb-1">
                  Invoice Amount
                </Form.Label>
                <CurrencyCustomInput
                  placeholder="0.00"
                  value={InvoiceAmount}
                  readOnly
                />
              </Form.Group> */}
              {/*  */}
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Reorder Level</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Reorder Level"
                  name="Reorder_Level"
                  value={formik.values.Reorder_Level}
                  onValueChange={(value, name) => {
                    formik.setFieldValue(name, value);
                  }}
                />
              </Form.Group>
              {/*  */}
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Branch</Form.Label>
                <Form.Control defaultValue={formik.values.Branch} readOnly />
              </Form.Group>
              {/*  */}
            </div>
            {/*  */}
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Description"
                name="Item_Desc"
                value={formik.values.Item_Desc}
                onChange={formik.handleChange}
              />
            </Form.Group>
            {/*  */}

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Manufacturing Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.ManDate}
                  onChange={(date) => {
                    formik.setFieldValue("ManDate", date, true);
                  }}
                />
              </Form.Group>
              {/*  */}

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Expire Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.ExpireDate}
                  onChange={(date) => {
                    formik.setFieldValue("ExpireDate", date, true);
                  }}
                />
              </Form.Group>
              {/*  */}
            </div>
            {/*  */}
            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Maintance</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={maintainceData}
                  value={maintainceData.find(
                    (el) => el.value === formik.values.Calibration
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Calibration", value)
                  }
                />
              </Form.Group>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Validity of Maintance </Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={Validity}
                  value={Validity.find(
                    (el) => el.value === formik.values.ValidityofCal
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("ValidityofCal", value)
                  }
                />
              </Form.Group>
            </div>
            {/*  */}

            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Last Maintance</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.LastCalibration}
                  onChange={(date) => {
                    formik.setFieldValue("LastCalibration", date, true);
                  }}
                />
              </Form.Group>
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Next Maintance Date</Form.Label>
                <Form.Control
                  value={nextmaintance.format("MMM DD, YYYY")}
                  readOnly
                />
              </Form.Group>

              {/*  */}
              {/* <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Maintain By</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Maintain By"
                  name="Maintain_By"
                  value={formik.values.Maintain_By}
                  onChange={formik.handleChange}
                />
              </Form.Group> */}
              <div className="col-6 text-nowrap align-items-center">
                <Form.Group className="mb-3 pb-2">
                  <Form.Label className="mb-1">Maintain By</Form.Label>
                  <Select
                    classNamePrefix={`form-select`}
                    options={data.vendors}
                    value={data?.vendors?.find(
                      (el) => el.value === formik.values.Maintain_By
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("Maintain_By", value)
                    }
                  />
                  {formik.touched.Maintain_By && formik.errors.Maintain_By ? (
                    <span className="text-danger mt-2">
                      {formik.errors.Maintain_By}
                    </span>
                  ) : null}
                </Form.Group>

                {/* <Form.Group className="col-2 pb-2 px-0">
                  <Button
                    size="sm"
                    onClick={() => setShowCreateNewVendorModal(true)}
                  >
                    + Add
                  </Button>
                </Form.Group> */}
              </div>
              {/*  */}
              {/* <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Certified By</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Certified By"
                  name="Certified_By"
                  value={formik.values.Certified_By}
                  onChange={formik.handleChange}
                />
              </Form.Group> */}

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Certified By</Form.Label>
                <Select
                  classNamePrefix={"form-select"}
                  isSearchable={false}
                  value={data?.staffs?.find(
                    (el) => el.value === formik.values.Certified_By
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Certified_By", value)
                  }
                  options={data?.staffs}
                />

                {formik.touched.Certified_By && formik.errors.Certified_By ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Certified_By}
                  </span>
                ) : null}
              </Form.Group>

              {/*  */}
            </div>

            {/*  */}
            <div className="row">
              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">WareHouse</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.warehouses}
                  value={data?.warehouses?.find(
                    (el) => el.value === formik.values.Warehouse
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("Warehouse", value)
                  }
                />
                {formik.touched.Warehouse && formik.errors.Warehouse ? (
                  <span className="text-danger mt-2">
                    {formik.errors.Warehouse}
                  </span>
                ) : null}
              </Form.Group>
              {/*  */}

              <Form.Group className="col-6 mb-3 pb-2">
                <Form.Label className="mb-1">Storage Location</Form.Label>
                <CreatableSelect
                  classNamePrefix={`form-select`}
                  options={data.storagelocation}
                  value={data?.storagelocation?.find(
                    (el) => el.value === formik.values.StorageLocation
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("StorageLocation", value)
                  }
                />
                {formik.touched.StorageLocation &&
                formik.errors.StorageLocation ? (
                  <span className="text-danger mt-2">
                    {formik.errors.StorageLocation}
                  </span>
                ) : null}
              </Form.Group>
            </div>
          </div>
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button
          onClick={() => formik.submitForm()}
          variant="primary"
          type="submit"
          className="w-50 submit-btn mt-3"
          disabled={createMutation.isLoading}
        >
          {createMutation.isLoading ? "Please wait..." : "Send To Inventory"}
        </Button>
      </Modal.Footer>

      {showCreateNewVendorModal && (
        <NewVendorModal
          showCreateNewVendorModal={showCreateNewVendorModal}
          setShowCreateNewVendorModal={setShowCreateNewVendorModal}
          setSelectedVendor={setSelectedVendor}
        />
      )}
    </Modal>
  );
}
