import { Modal, Button, Table, InputGroup, Form } from "react-bootstrap";
import { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Select from "react-select";
import { Popover } from "react-tiny-popover";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import currency from "currency.js";
import DateTime from "react-datetime";
import moment from "moment";

import { CustomInput } from "../utils/CustomInput";
import { useAuth } from "../../hooks/useAuth";
import "../../assets/scss/scoped/issueItem.scss";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";
import GetTemplateModal from "./GetTemplateModal";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { DeleteIcon } from "../Icons";
import ModalLoader from "../utils/ModalLoader";

const initialValues = {
  Bar_Code: "",
  Item_Name: "",
  Item_Desc: "",
  Product_Name: "",
  Cat_Name: "",
  RequesterName: "",
  Requester: "",
  UnitCost: "",
  Date_Log: moment(new Date()),
  Quantity: "",
  UserName: "",
};

export const CreateProductLogModal = (props) => {
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [activeTableIndex, setActiveTableIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});

  const { backendUrl, token, user } = useAuth();

  const handleInputChange = ({ index, name, value }) => {
    const oldTableData = tableData;
    oldTableData[index][name] = value;
    setTableData([...oldTableData]);
  };

  const plantLocal = JSON.parse(window.localStorage.getItem("plantDetails"));

  const fetchItems = async () => {
    const data = await fetchActionsUtil(
      `${backendUrl}/api/production/IssueTo_Officers`,
      "GET",
      token
    );
    data.staffs = data.staffs.map((el) => ({
      value: el.Staff_ID,
      label: el.Name,
    }));

    data.warehouse = data.warehouse.map((el) => ({
      value: el.W_ID,
      label: el.W_name,
    }));

    return data;
  };

  const { data, isLoading } = useQuery(["GET_OTHERS"], () => fetchItems(), {
    enabled: true,
  });

  const sendProductLog = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/production/product-log/${plantLocal.PLID}`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, data }) => {
        toast.success("Successfully Created");

        formik.resetForm({
          values: {
            ...initialValues,
          },
        });

        setTableData([]);
        props.refetchMain();
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object().shape({
      Bar_Code: yup.string().required("Bar Code is required"),
      Item_Name: yup.string().required("Item Name is required"),
      Quantity: yup.string().required("Quantity is required"),
      Product_Name: yup.string().required("Product is required"),
      Cat_Name: yup.string().required("Category by is required"),
    }),
    onSubmit: async (values) => {
      if (tableData.length < 1) {
        return toast.error("Add Items");
      }

      if (
        await ConfirmDialog({
          title: "Save Production",
          description: "Are you sure you want to save this production",
        })
      ) {
        // const payload = {
        //   ...values,
        //   tableData,
        // };
        console.log(tableData);
        sendProductLog.mutate(tableData);
      }
    },
  });

  const handleSelectedItem = (el) => {
    // console.log(el);
    formik.setFieldValue("Bar_Code", el.Bar_Code);
    formik.setFieldValue("Item_Name", el.Item_Name);
    formik.setFieldValue("Product_Name", el.Product_Name);
    formik.setFieldValue("Cat_Name", el.Cat_Name);
    formik.setFieldValue("UnitCost", el.UnitCost);
    formik.setFieldValue("UserName", user.username);
    setSelectedItem(el);
    setShowItemsPopover(false);
  };

  const onDelete = (barcode) => {
    const item = tableData.filter((d) => d.Bar_Code !== barcode);
    setTableData(item);
  };

  const addToTable = async (el) => {
    if (el.Bar_Code === "") {
      return toast.error("Enter Item Id");
    }
    if (el.Quantity === "") {
      return toast.error("Quantity");
    }
    if (el.Requester === "") {
      return toast.error("Choose Issue To");
    }
    if (el.RequesterName === "") {
      return toast.error("Choose Receiving Officer");
    }
    const newData = { ...selectedItem, ...el };
    try {
      const { message } = await fetchActionsUtil(
        `${backendUrl}/api/production/check-quantity-items/${plantLocal.PLID}`,
        "POST",
        token,
        newData
      );
      if (message.length > 0) {
        return toast.error(message[0]);
      }
    } catch (error) {
      console.log(error);
      return;
    }

    if (!tableData.length) {
      setTableData([{ ...selectedItem, ...el }]);
    } else {
      const index = tableData.findIndex((d) => d.Bar_Code === el.Bar_Code);
      if (index === -1) {
        setTableData([...tableData, newData]);
      } else {
        return toast.error("Already added");
      }
    }
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      backdropClassName={`global-backdrop`}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="my-style-modal"
      animation={false}
      enforceFocus={false}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <div>
            <h1>Product Log</h1>
          </div>

          <p>Add product log by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="container">
            <div>
              <div className="form-relative">
                {/*  */}
                <div className="row">
                  <div className="col-4 mb-3">
                    <label htmlFor="date" className="form-label">
                      Item ID
                    </label>
                    <InputGroup>
                      <Form.Control
                        name="Bar_Code"
                        placeholder="Enter barcode"
                        value={formik.values.Bar_Code}
                      />

                      <Popover
                        isOpen={showItemsPopover}
                        onClickOutside={() => setShowItemsPopover(false)}
                        content={() => (
                          <GetTemplateModal
                            handleSelectedItem={handleSelectedItem}
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowItemsPopover(!showItemsPopover)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>

                    {formik.touched.Bar_Code && !!formik.errors.Bar_Code ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Bar_Code}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-4 mb-3">
                    <CustomInput
                      name="Item_Name"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Item Name"
                      disabled
                      value={formik.values.Item_Name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-4 mb-3">
                    <CustomInput
                      name="Cat_Name"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Category"
                      disabled
                      value={formik.values.Cat_Name}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
                {/*  */}

                <div className="row">
                  <div className="col-4 mb-3">
                    <CustomInput
                      name="Product_Name"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Product"
                      disabled
                      value={formik.values.Product_Name}
                      onChange={formik.handleChange}
                    />
                  </div>

                  <div className="col-4 mb-3">
                    <label htmlFor="date" className="form-label">
                      Issue To
                    </label>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={true}
                      value={data?.warehouse?.find(
                        (el) => el.value === formik.values.Requester
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("Requester", value);
                      }}
                      options={data?.warehouse}
                    />

                    {formik.touched.Requester && !!formik.errors.Requester ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Requester}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-4 mb-3">
                    <label htmlFor="date" className="form-label">
                      Receiving Officer
                    </label>
                    <Select
                      classNamePrefix={"form-select"}
                      isSearchable={true}
                      value={data?.staffs.find(
                        (el) => el.value === formik.values.RequesterName
                      )}
                      onChange={({ value }) => {
                        formik.setFieldValue("RequesterName", value);
                      }}
                      options={data?.staffs}
                    />

                    {formik.touched.RequesterName &&
                    !!formik.errors.RequesterName ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.RequesterName}
                      </span>
                    ) : null}
                  </div>
                </div>
                {/*  */}

                <div className="row">
                  <div className="col-4 mb-3">
                    <CustomInput
                      name="Quantity"
                      placeholder="Enter Quantity"
                      typeOfInput="text"
                      label="Quantity"
                      value={formik.values.Quantity}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.Quantity && !!formik.errors.Quantity ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.Quantity}
                      </span>
                    ) : null}
                  </div>

                  <div className="col-4 mb-3">
                    <CustomInput
                      name="UnitCost"
                      placeholder="Enter Item Name"
                      typeOfInput="text"
                      label="Cost Price"
                      value={formik.values.UnitCost}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.UnitCost && !formik.errors.UnitCost ? (
                      <span className="custom-invalid-feedback">
                        {formik.errors.UnitCost}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-4">
                    <label htmlFor="date" className="form-label">
                      Production Date
                    </label>
                    <DateTime
                      timeFormat={false}
                      closeOnSelect={true}
                      closeOnClickOutside={true}
                      dateFormat="MMM DD, YYYY"
                      inputProps={{
                        className: `date-input form-control`,
                        placeholder: "Select date",
                        readOnly: true,
                      }}
                      value={formik.values.Date_Log}
                      onChange={(date) => {
                        formik.setFieldValue("Date_Log", date, true);
                      }}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => addToTable(formik.values)}
                    className="btn btn-primary"
                  >
                    Add To Cart
                  </button>
                </div>

                {/*  */}
                {/* Table */}

                <div className="mt-5 mb-4">
                  <Table
                    responsive
                    borderless
                    striped
                    className="product-table"
                  >
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Bar Code</th>
                        <th>Name</th>
                        <th className="text-center">Quantity</th>
                        <th>Unit Cost</th>
                        <th>Sub Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.length > 0 &&
                        tableData?.map((el, index) => (
                          <tr
                            key={index}
                            className={`${activeTableIndex === index &&
                              "active-row"}`}
                            onClick={() =>
                              activeTableIndex !== index &&
                              setActiveTableIndex(index)
                            }
                          >
                            <td>{index + 1}</td>
                            <td>{el.Bar_Code}</td>
                            <td>{el.Item_Name}</td>
                            <td>
                              <input
                                className="border-0 text-center"
                                type="number"
                                value={el.Quantity}
                                onKeyDown={(e) =>
                                  e.key === "Enter" && e.target.blur()
                                }
                                name="Quantity"
                                onChange={(e) =>
                                  handleInputChange({
                                    index,
                                    name: e.target.name,
                                    value: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>{el?.UnitCost}</td>
                            <td className="d-flex align-items-center">
                              <span>
                                {currency(
                                  parseFloat(el?.UnitCost) *
                                    parseInt(el?.Quantity),
                                  {
                                    symbol: "",
                                  }
                                ).format()}
                              </span>
                              <span
                                className="mx-3 p-cursor"
                                onClick={() => onDelete(el.Bar_Code)}
                              >
                                <DeleteIcon />
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>

                {/*  */}
                <div className="add-to-cart-holder d-flex justify-content-between text-primary mt-5">
                  <div>
                    <Button
                      variant="outline-primary"
                      onClick={formik.handleSubmit}
                      disabled={sendProductLog.isLoading}
                    >
                      {sendProductLog.isLoading
                        ? "Sending..."
                        : " Post Production"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <ModalLoader show={sendProductLog.isLoading || isLoading} />
            {/*  */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
