import {
  Modal,
  Form,
  Button,
  InputGroup,
  Table,
  Dropdown,
  Row,
} from "react-bootstrap";
import "./../../assets/scss/new-entity-modal.scss";
import Select from "react-select";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import { DeleteIcon, EditIcon, LockIcon, NoSelectedItemIcon } from "./../Icons";
import NumberCustomInput from "../utils/NumberCustomInput";
import DateTime from "react-datetime";
import { useAuth } from "../../hooks/useAuth";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { queryActions } from "../../utils/reactQueryActions";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";
import currency from "currency.js";
import moment from "moment";
import { toast } from "react-toastify";
import CachedIcon from "mdi-react/CachedIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import ItemsTable from "../utils/ItemsTable";
import CurrencyInput from "react-currency-input-field";
import {
  getDataForEdit,
  pcsToTons,
  qtyFormat,
  qtyFormatToString,
  scrollToElement,
  styleHelper,
  tonsToPcs,
  Units,
} from "./../../utils/helpers";
import { isEmpty } from "lodash";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useRef } from "react";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import { appSettings } from "../../config";
import LoginDialog from "../LoginDialog";
import ModalLoader from "../utils/ModalLoader";
import ConfirmDialogue from "../ConfirmDialogue";
import { useEffect } from "react";
import DamagesTable from "../utils/DamagesTable";
import { useEffectOnce } from "../../utils/hooks";
import { cloneDeep } from "lodash";

const pointsOfDamages = [
  {
    label: "Company store",
    value: "Company store",
  },
  {
    label: "Transit",
    value: "Transit",
  },
  {
    label: "Customer Possession",
    value: "Customer Possession",
  },
  {
    label: "Manufacturer",
    value: "Manufacturer",
  },
  {
    label: "Point of sales",
    value: "Point of sales",
  },
  {
    label: "Others",
    value: "Others",
  },
];

const damageStatusOptions = [
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Replaced By Vendor",
    value: "Replaced By Vendor",
  },
  {
    label: "Credit Note from Vendor",
    value: "Credit Note from Vendor",
  },
  {
    label: "Quarantined",
    value: "Quarantined",
  },
];

export default function UpdateDamageItemModal({
  showUpdateDamageItemModal,
  setShowUpdateDamageItemModal,
  refetch,
  isMulti = true,
  selectedFaultItem,
  setSelectedFaultItem,
}) {
  const barcodeInputRef = useRef();
  const itemMeasurements = useStoreState((state) => state.itemMeasurements);
  const { backendUrl, token, isIronRod } = useAuth();
  const queryClient = useQueryClient();
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [tableData, setTableData] = useState([]);

  const setUp = async (department) => {
    let response = await fetch(`${backendUrl}/api/items/set-up`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    data.vendors = data?.vendors.map((el) => ({
      label: el.CompanyName,
      value: el.CompanyName,
    }));
    data.itemMeasurement = data?.itemMeasurement.map((el) => ({
      label: el.Size,
      value: el.Size,
    }));

    data.categories = data.categories.map((el) => ({
      value: el.Cat_Name,
      label: el.Cat_Name,
    }));

    data.products = data.products.map((el) => ({
      value: el.Product_Name,
      label: el.Product_Name,
    }));

    return data;
  };
  const {
    data = {
      vendors: [],
      itemMeasurement: [],
    },
  } = useQuery([queryActions.ITEMS_SETUP], () => setUp(), {
    enabled: true,
  });

  const formik = useFormik({
    initialValues: {
      FaultID: ``,
      Barcode: "",
      Item_Name: "",
      Quantity: "",
      Vendor: "",
      PointOfDamage: "Company store",
      Faults: "",
      CompanySentTo: "",
      DamageStatus: "Active",
      CompanyAddress: "",
      EntryDate: moment(),
      DateResolved: moment(),
      Size: appSettings.isBatchStandard ? "" : "8 mm",
      Unit: appSettings.isBatchStandard ? "Each" : "Tons",
      Usage: "Sales",

      InvoiceNo: "",
      UnitCost: "",
      amount: "",

      /*  Product_Name: "",
      Cat_Name: "",
      Branch: "HQ", */
      damagedQuantity: 0,
      balanceLeftToReturn: 0,
    },
    validationSchema: yup.object().shape({
      Barcode: yup.string().required("Item Code is required"),
      Item_Name: yup.string().required("Item Name is required"),
      Quantity: yup.string().required(),
      FaultID: yup.string().required(),
      //InvoiceNo: yup.string().required(),
    }),
    onSubmit: async (values) => {
      if (isMulti) {
        const realQuantity =
          values.Unit === "Tons"
            ? tonsToPcs(values.Quantity, values?.Item_Desc, itemMeasurements)
            : values.Quantity;

        if (
          values.DamageStatus === "Replaced By Vendor" &&
          Number(realQuantity) > Number(values.balanceLeftToReturn)
        ) {
          return formik.setFieldError(
            "Quantity",
            `Quantity cannot be higher than balance ${
              values.balanceLeftToReturn
            } left in damage`
          );
        }

        if (Number(realQuantity) > values?.damagedQuantity) {
          return formik.setFieldError(
            "Quantity",
            `Quantity cannot be greater than what was set as damaged`
          );
        }
        const foundIndex = tableData.findIndex(
          (el) => el.FaultID === values.FaultID
        );

        if (foundIndex !== -1) {
          tableData[foundIndex] = values;
          setTableData([...tableData]);
        } else {
          setTableData([...tableData, values]);
        }

        formik.resetForm();
        // formik.setFieldValue("InvoiceNo", values.InvoiceNo);
        //formik.setFieldValue("Vendor", values.Vendor);
        formik.setFieldValue("FaultID", `FLT${Date.now()}`);
        barcodeInputRef.current.focus();
        return;
      }
    },
    onReset: () => {
      // handleSelectedItem(null);
    },
  });

  const recordDamageUpdate = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(`${backendUrl}/api/items/update-damage`, {
      method: "POST",
      credentials: "include",
      body: formData,
      headers: {
        /*  Accept: "Application/json",
        "Content-Type": "Application/json", */
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const recordDamageUpdateMutation = useMutation(
    (payload) => recordDamageUpdate(payload),
    {
      onSuccess: ({ message }) => {
        toast.success(message);
        formik.resetForm();
        if (refetch) refetch();
        setShowUpdateDamageItemModal(false);
      },
      onError: ({ message = "" }) => {
        toast.error(`Unable to perform action: ${message}`);
      },
    }
  );

  const handleSelectedItem = (faultItemData) => {
    const faultItem = cloneDeep(faultItemData);
    let damagedQuantity = faultItem.Quantity;
    let balanceLeftToReturn;
    // item here is comming from damages table
    //  console.log(faultItem);

    // Quantity should be balance
    if (faultItem.DamageStatus === "Replaced By Vendor") {
      balanceLeftToReturn = currency(faultItem.Quantity, {
        symbol: "",
        separator: "",
        precision: 1,
      })
        .subtract(faultItem.QuantityReplaced)
        .format();

      damagedQuantity = faultItem.Quantity;
      faultItem.Quantity = balanceLeftToReturn;
    }

    if (!faultItem?.itemData) return toast.error("Item not found in Inventory");
    const item = faultItem.itemData;
    // get format type
    const { Tons, Pieces, formatType } = getDataForEdit({
      qty: faultItem.Quantity,
      desc: item?.Item_Desc,
      itemMeasurements,
    });

    // sale Type
    const saleType =
      Number(Tons) > 0
        ? "Tons"
        : Number(Pieces) > 0 && !formatType
        ? "Pieces"
        : item.Product_Model;

    formik.resetForm();
    formik.setValues({
      ...formik.values,

      // Data from item Table
      Item_Type: item.Item_Type,
      Barcode: item.Bar_Code,
      Item_Name: item.Item_Name,
      Size: item.Item_Desc,
      Unit: saleType,
      Product_Name: item.Product_Name,
      Cat_Name: item.Cat_Name,
      Branch: item.Branch,
      UnitCost: faultItem.Unit_Cost,
      Vendor: item.Vendor,

      ...faultItem,
      Quantity: Number(Tons) > 0 ? Tons : Pieces,
      EntryDate: new Date(faultItem.EntryDate),
      DateResolved: new Date(faultItem.DateResolved),

      // --------------------------------------------
      Item_Desc: item.Item_Desc,
      damagedQuantity,

      InvoiceNo: faultItem.CompanyAddress,
      balanceLeftToReturn,
    });
    setSelectedItem(faultItem);
    setShowItemsPopover(false);
    scrollToElement('input[name="Quantity"]', { scroll: false });
    calculateAmount(faultItem.Quantity, item.UnitCost);
  };

  useEffectOnce(() => {
    console.log("once");
    if (selectedFaultItem) handleSelectedItem(selectedFaultItem);
  });

  const handleSelectedItemToEdit = (item) => {
    formik.resetForm();
    formik.setValues({
      ...formik.values,
      ...item,
    });
    setSelectedItem(item);
    setShowItemsPopover(false);
    scrollToElement('input[name="Quantity"]', { scroll: false });
  };

  const handleRemoveItem = (index) => {
    setTableData([...tableData.filter((el, i) => i !== index)]);
  };

  const saveMulti = () => {
    recordDamageUpdateMutation.mutate({
      isMulti: true,
      stockItems: tableData,
    });
  };

  const calculateAmount = (Quantity, UnitCost) => {
    formik.setFieldValue(
      "amount",
      currency(Quantity, {
        symbol: "",
        separator: "",
      })
        .multiply(UnitCost)
        .format()
    );
  };

  return (
    <Modal
      show={showUpdateDamageItemModal}
      onHide={() => {
        setSelectedFaultItem(null);
        setShowUpdateDamageItemModal(false);
      }}
      dialogClassName={`new-entity-modal ${isMulti ? "multi-update" : ""}`}
      backdropClassName={`global-backdrop`}
      centered={true}
      animation={false}
      enforceFocus={false}
      backdrop="static"
      fullscreen={styleHelper.isMobile}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>
            Update Damage Item Information{" "}
            <CachedIcon
              onClick={() => formik.resetForm()}
              className="text-primary"
            />{" "}
          </h1>
          <p>Add new item by filling in the following forms.</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={`px-0 ${isMulti ? "d-md-flex pb-1" : ""}`}>
        <Form
          noValidate
          onSubmit={formik.handleSubmit}
          className={`row new-item-form w-100 m-0 ${
            isMulti ? "flex-column border rounded mx-4" : ""
          } `}
          autoComplete="off"
        >
          <div className={`${isMulti ? "w-auto pt-4" : "col-md-6 px-4"}`}>
            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Fault ID</Form.Label>
              <InputGroup>
                <Form.Control
                  className=""
                  type="text"
                  placeholder="Enter FaultID"
                  name="FaultID"
                  value={formik.values.FaultID}
                  onChange={formik.handleChange}
                  isInvalid={formik.touched.FaultID && !!formik.errors.FaultID}
                  ref={barcodeInputRef}
                />

                <Popover
                  isOpen={showItemsPopover}
                  onClickOutside={() => setShowItemsPopover(false)}
                  content={() => (
                    <DamagesTable
                      handleSelectedItem={handleSelectedItem}
                      isIronRod={isIronRod}
                    />
                  )}
                  position="bottom"
                >
                  <InputGroup.Text
                    onClick={() => setShowItemsPopover(!showItemsPopover)}
                  >
                    <MagnifyIcon />
                  </InputGroup.Text>
                </Popover>
              </InputGroup>
              <Form.Control.Feedback type="invalid">
                {formik.errors.FaultID}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Code</Form.Label>

              <Form.Control
                className=""
                type="text"
                placeholder="Enter Item Code"
                name="Barcode"
                value={formik.values.Barcode}
                onChange={formik.handleChange}
                isInvalid={formik.touched.Barcode && !!formik.errors.Barcode}
                readOnly
              />

              <Form.Control.Feedback type="invalid">
                {formik.errors.Barcode}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Item Name</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Item Name"
                name="Item_Name"
                value={formik.values.Item_Name}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.Item_Name && !!formik.errors.Item_Name
                }
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.Item_Name}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Invoice No</Form.Label>
              <Form.Control
                className=""
                type="text"
                placeholder="Enter Invoice No"
                name="InvoiceNo"
                value={formik.values.InvoiceNo}
                onChange={formik.handleChange}
                isInvalid={
                  formik.touched.InvoiceNo && !!formik.errors.InvoiceNo
                }
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.InvoiceNo}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label className="mb-1">Unit</Form.Label>
              <Select
                classNamePrefix={`form-select`}
                options={Units}
                value={Units.find((el) => el.value === formik.values.Unit)}
                onChange={({ value }) => formik.setFieldValue("Unit", value)}
              />
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className="mb-3 pb-2">
              <Form.Label>Quantity ({formik.values.Unit})</Form.Label>
              <NumberCustomInput
                placeholder="0"
                name="Quantity"
                value={formik.values.Quantity}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                  calculateAmount(value, formik.values.UnitCost);
                }}
                isInvalid={formik.touched.Quantity && !!formik.errors.Quantity}
                onBlur={() => formik.setFieldTouched("Quantity", true)}
              />
              {formik.touched.Quantity && !!formik.errors.Quantity ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.Quantity}
                </span>
              ) : null}
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Damaged Quantity</Form.Label>
              <Form.Control
                placeholder="DamagedQuantity"
                value={
                  formik.values?.damagedQuantity
                    ? qtyFormatToString(
                        qtyFormat(
                          formik.values?.damagedQuantity,
                          formik.values?.Item_Desc,
                          itemMeasurements
                        )
                      )
                    : "..."
                }
                readOnly
              />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1"> Cost Price</Form.Label>
              <CurrencyCustomInput
                name="UnitCost"
                placeholder="0.00"
                value={formik.values.UnitCost}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                  calculateAmount(value, formik.values.Quantity);
                }}
                isInvalid={formik.touched.UnitCost && !!formik.errors.UnitCost}
                onBlur={() => formik.setFieldTouched("UnitCost", true)}
              />
              {formik.touched.UnitCost && !!formik.errors.UnitCost ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.UnitCost}
                </span>
              ) : null}
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Point of Damage</Form.Label>

              <Select
                classNamePrefix={"form-select"}
                options={pointsOfDamages}
                value={pointsOfDamages?.find(
                  (el) => el.value === formik.values.PointOfDamage
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("PointOfDamage", value)
                }
                isSearchable={false}
              />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Fault</Form.Label>
              <Form.Control
                as="textarea"
                name="Faults"
                placeholder="Enter Faults"
                value={formik.values.Faults}
                onChange={formik.handleChange}
              />
            </Form.Group>

            <hr />

            <Row>
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Return To</Form.Label>
                <Select
                  classNamePrefix={`form-select`}
                  options={data.vendors}
                  value={data?.vendors?.find(
                    (el) => el.value === formik.values.CompanySentTo
                  )}
                  onChange={({ value }) =>
                    formik.setFieldValue("CompanySentTo", value)
                  }
                  isSearchable={false}
                />
                {formik.touched.CompanySentTo && formik.errors.CompanySentTo ? (
                  <span className="text-danger mt-2">
                    {formik.errors.CompanySentTo}
                  </span>
                ) : null}
              </Form.Group>
              <Form.Group className="col-md-6 mb-3 pb-2">
                <Form.Label className="mb-1">Tran Date</Form.Label>
                <DateTime
                  timeFormat={false}
                  closeOnSelect={true}
                  closeOnClickOutside={true}
                  dateFormat="MMM DD, YYYY"
                  inputProps={{
                    className: `date-input form-control`,
                    placeholder: "Select date",
                    readOnly: true,
                  }}
                  value={formik.values.EntryDate}
                  onChange={(date) => {
                    formik.setFieldValue("EntryDate", date, true);
                  }}
                />
              </Form.Group>
            </Row>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1"> Amount</Form.Label>
              <CurrencyCustomInput
                name="amount"
                placeholder="0.00"
                value={formik.values.amount}
                onValueChange={(value, name) => {
                  formik.setFieldValue(name, value);
                }}
                isInvalid={formik.touched.amount && !!formik.errors.amount}
                onBlur={() => formik.setFieldTouched("amount", true)}
              />
              {formik.touched.amount && !!formik.errors.amount ? (
                <span className="custom-invalid-feedback">
                  {formik.errors.amount}
                </span>
              ) : null}
              <Form.Control.Feedback type="invalid" />
            </Form.Group>

            <Form.Group className=" mb-3 pb-2">
              <Form.Label className="mb-1">Damage Status</Form.Label>
              <Select
                classNamePrefix={"form-select"}
                options={damageStatusOptions}
                value={damageStatusOptions.find(
                  (el) => el.value === formik.values.DamageStatus
                )}
                onChange={({ value }) =>
                  formik.setFieldValue("DamageStatus", value)
                }
                isSearchable={false}
              />
            </Form.Group>
          </div>

          {isMulti && (
            <div className="d-flex justify-content-end py-3 add-to-cart-holder">
              <Button
                variant="outline-primary"
                type="submit"
                className="action-btn"
              >
                Add To Cart
              </Button>
            </div>
          )}
        </Form>

        {isMulti && (
          <div className="item-cart-area px-4">
            {!isEmpty(tableData) ? (
              <>
                {" "}
                <h2 className="mb-4">Item Cart</h2>
                <Table
                  responsive
                  borderless
                  striped
                  className="product-table text-nowrap"
                >
                  <thead>
                    <tr>
                      <th />
                      <th>Fault ID</th>
                      <th>Barcode</th>
                      <th>Item Name</th>
                      <th>Quantity</th>
                      <th>Vendor</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((el, index) => (
                      <tr key={index} className="p-cursor">
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant=""
                              className="bg-white border-0"
                              bsPrefix="print more"
                            >
                              <DotsVerticalIcon />
                            </Dropdown.Toggle>
                            <Dropdown.Menu
                              popperConfig={{
                                strategy: "fixed",
                              }}
                              renderOnMount
                              className="dropdown-with-icons"
                            >
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleSelectedItemToEdit(el)}
                              >
                                <EditIcon />
                                Edit Item
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="button"
                                onClick={() => handleRemoveItem(index)}
                              >
                                <DeleteIcon />
                                Remove Item
                              </Dropdown.Item>
                            </Dropdown.Menu>{" "}
                          </Dropdown>
                        </td>
                        <td>{el.FaultID}</td>
                        <td>{el.Barcode}</td>
                        <td>{el.Item_Name}</td>
                        <td>
                          {currency(el.Quantity, { symbol: "" }).format()}{" "}
                          {["Tons", "Pieces"].includes(el.Unit) ? el.Unit : ""}
                        </td>
                        <td>{el.CompanySentTo}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-end save-btn-holder">
                  <Button
                    disabled={recordDamageUpdateMutation.isLoading}
                    className="action-btn save-btn"
                    onClick={() => saveMulti()}
                  >
                    Update
                  </Button>
                </div>
              </>
            ) : (
              <div className="no-item my-5 py-4">
                <div className="info">
                  <NoSelectedItemIcon />
                  <h2 className="mb-1">No Item Selected</h2>
                  <p>Please select an item and fill in the form</p>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal.Body>

      <ModalLoader show={recordDamageUpdateMutation.isLoading} />
    </Modal>
  );
}
