import { useMemo } from "react";
import { NavLink } from "react-router-dom";

export function WarehouseInventoryNav(props) {
  const wareshouseLocal = JSON.parse(
    window.localStorage.getItem("warehouseName")
  );

  const items = useMemo(() => {
    return [
      {
        name: "Inventory Entry",
        to: "inventry-entry",
      },
      {
        name: "Item Issuer",
        to: "item-issuer",
      },
      {
        name: "Receive Item",
        to: "receive-item",
      },
      wareshouseLocal?.cater === "Assets" && {
        name: "Maintenance",
        to: "maintance",
      },
      // {
      //   name: "Vendor",
      //   to: "vendor",
      // },
      // {
      //   name: "Manage Transaction",
      //   to: "manage-transaction",
      // },
    ];
  }, [wareshouseLocal?.cater]);

  return (
    <div className="dashboard-tabs innerpage-tabs px-4">
      <ul className="nav nav-tabs">
        {items.map((item, index) => (
          <li key={index} className="nav-item">
            {Object.entries(item).length > 0 && (
              <NavLink className={`nav-link ga-nav-tab`} to={item.to}>
                {item.name}
              </NavLink>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
