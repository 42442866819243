import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import Select from "react-select";
import { useFormik } from "formik";
import * as yup from "yup";
import CreatableSelect from "react-select/creatable";

import { useAuth } from "../../hooks/useAuth";
import { fetchActionsUtil } from "../../utils/helpers";
import ConfirmDialog from "../ConfirmDialogue";
import ModalLoader from "../utils/ModalLoader";
import AddAccounts from "./AddAccounts";

const wareCategory = ["", "Stock", "Assets", "Comsumables"];

const EditWarehouseModal = (props) => {
  const { backendUrl } = useAuth();

  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getThings = async (warehouseName) => {
    const staffs = {};
    const acc = await fetchActionsUtil(
      `${backendUrl}/api/accounts/accountLists/CURRENT ASSET`,
      "GET"
    );

    const { ContentTypes } = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/contentTypes`,
      "GET"
    );

    const { warehouses } = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/${warehouseName}`,
      "GET"
    );

    staffs.contentTypes = ContentTypes.map((el) => ({
      value: el.serial,
      label: el.name,
    }));

    staffs.accounts = acc.accountDes.map((el) => ({
      value: el.AccountID,
      label: el.Description,
    }));

    staffs.warehouse = warehouses[0];

    return staffs;
  };

  const { data, isFetching, refetch } = useQuery(
    ["FETCH_WAREHOUSE_STAFFS", props.singleWarehouse],
    () => getThings(props.singleWarehouse),
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    formik.setFieldValue("W_name", data?.warehouse?.W_name);
    formik.setFieldValue("W_type", data?.warehouse?.WH_ContentType?.serial);
    formik.setFieldValue("W_Category", data?.warehouse?.W_Category);
    formik.setFieldValue("account_WH", data?.warehouse?.WH_Accounts?.AccountID);
    // console.log(data?.warehouse);
  }, [data]);

  // console.log(data);
  const saveWarehouse = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/warehouse/${props.warehouseId}`,
        "PUT",
        "",
        payload
      ),
    {
      onSuccess: () => {
        toast.success("Warehouse Edited Successfully");
        props.refetch();
        props.onHide();
      },
      onError: ({ message }) => {
        message
          ? toast.error(message)
          : toast.error("Unable to edit warehouse");
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      W_name: "",
      W_type: "",
      W_Category: "",
      account_WH: "",
    },
    validationSchema: yup.object().shape({
      W_name: yup.string().required("Warehouse Name is required"),
      W_type: yup.string().required("Warehouse Type is required"),
      W_Category: yup.string().required("Warehouse Category is required"),
      account_WH: yup.string().required("Acount is required"),
    }),
    onSubmit: async (values) => {
      if (
        await ConfirmDialog({
          title: "Create Warehouse",
          description: `Are you sure you want to Create this warehouse `,
        })
      ) {
        saveWarehouse.mutate(values);
      }
    },
  });

  const openAccount = () => {
    if (formik.values.W_name === "") {
      return formik.setFieldError("W_name", "Warehouse Name is required");
    } else {
      setOpenAccountModal(true);
    }
  };

  const setAccountReturn_WH = (id) => {
    formik.setFieldValue("account_WH", id.toString());
  };

  const saveWarehouseContent = async (content) => {
    setIsLoading(true);
    const res = await fetchActionsUtil(
      `${backendUrl}/api/warehouse/contentTypes`,
      "POST",
      "",
      { name: content }
    );
    setIsLoading(false);
    if (res.message === "error") return toast.error("Content already exist");
    toast.success(res.message);
    refetch();
    formik.setFieldValue("W_type", res.data);
  };

  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div>
          <div>
            <div className="d-flex justify-content-between">
              <h6>Edit Warehouse</h6>
              <div role="button" className="text-lg" onClick={props.onHide}>
                x
              </div>
            </div>
            <p className="text-muted">Edit a new warehouse</p>
          </div>
          <div className="mt-3">
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Warehouse Name</label>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  value={formik.values.W_name}
                  name="W_name"
                  onChange={formik.handleChange}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.W_name}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">Content Type</label>
              <div className="form-group">
                <CreatableSelect
                  isClearable
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  onChange={(selected) => {
                    formik.setFieldValue("W_type", selected?.value);
                  }}
                  onCreateOption={saveWarehouseContent}
                  options={data?.contentTypes}
                  value={data?.contentTypes?.find(
                    (el) => el.value === formik.values.W_type
                  )}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.W_type}
                </span>
              </div>
            </div>
            {/*  */}
            <div className="mb-3">
              <label className="col-form-label fw-bold">
                Warehouse Category
              </label>
              <div className="form-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={formik.values.W_Category}
                  name="W_Category"
                  onChange={formik.handleChange}
                >
                  {wareCategory.map((a, i) => (
                    <option key={i} value={a}>
                      {a}
                    </option>
                  ))}
                </select>
                <span className="form-control-feedback text-danger">
                  {formik.errors.W_Category}
                </span>
              </div>
            </div>
            {/*  */}

            <div className="mb-3 row">
              <label className="col-form-label fw-bold">
                Map to GL account
              </label>
              <div className="form-group col-10">
                <Select
                  closeMenuOnSelect={true}
                  value={data?.accounts?.find(
                    (el) => el.value === formik.values.account_WH
                  )}
                  onChange={(selected) => {
                    formik.setFieldValue("account_WH", selected.value);
                  }}
                  options={data?.accounts}
                />
                <span className="form-control-feedback text-danger">
                  {formik.errors.account_WH}
                </span>
              </div>
              <div className="col-2">
                <button
                  size="sm"
                  className="btn btn-primary"
                  onClick={openAccount}
                >
                  + Add
                </button>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </Modal.Body>
      <div className="d-inline-flex justify-content-end m-4">
        <button className="btn bg print mx-4" onClick={props.onHide}>
          Cancel
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          onClick={formik.handleSubmit}
        >
          Edit Warehouse
        </button>
      </div>
      <ModalLoader show={isFetching || saveWarehouse.isLoading} />
      <AddAccounts
        onHide={() => setOpenAccountModal(false)}
        show={openAccountModal}
        warehouse={formik.values.W_name}
        setAccountReturn_WH={setAccountReturn_WH}
        refetch={refetch}
      />
    </Modal>
  );
};

export default EditWarehouseModal;
